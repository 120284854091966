;
(function($) {
    $.fn.camera = function(opts, callback) {
        var defaults = { alignment: 'center', autoAdvance: true, mobileAutoAdvance: true, barDirection: 'leftToRight', barPosition: 'bottom', cols: 6, easing: 'easeInOutExpo', mobileEasing: '', fx: 'random', mobileFx: '', gridDifference: 250, height: '50%', imagePath: 'images/', hover: true, loader: 'pie', loaderColor: '#eeeeee', loaderBgColor: '#222222', loaderOpacity: .8, loaderPadding: 2, loaderStroke: 7, minHeight: '200px', navigation: true, navigationHover: true, mobileNavHover: true, opacityOnGrid: false, overlayer: true, pagination: true, playPause: true, pauseOnClick: true, pieDiameter: 38, piePosition: 'rightTop', portrait: false, rows: 4, slicedCols: 12, slicedRows: 8, slideOn: 'random', thumbnails: false, time: 7000, transPeriod: 1500, onEndTransition: function() {}, onLoaded: function() {}, onStartLoading: function() {}, onStartTransition: function() {} };

        function isMobile() { if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) { return true; } }
        var opts = $.extend({}, defaults, opts);
        var wrap = $(this).addClass('camera_wrap');
        wrap.wrapInner('<div class="camera_src" />').wrapInner('<div class="camera_fakehover" />');
        var fakeHover = $('.camera_fakehover', wrap);
        fakeHover.append('<div class="camera_target"></div>');
        if (opts.overlayer == true) { fakeHover.append('<div class="camera_overlayer"></div>') } fakeHover.append('<div class="camera_target_content"></div>');
        var loader;
        if (opts.loader == 'pie' && $.browser.msie && $.browser.version < 9) { loader = 'bar'; } else { loader = opts.loader; }
        if (loader == 'pie') { fakeHover.append('<div class="camera_pie"></div>') } else if (loader == 'bar') { fakeHover.append('<div class="camera_bar"></div>') } else { fakeHover.append('<div class="camera_bar" style="display:none"></div>') }
        if (opts.playPause == true) { fakeHover.append('<div class="camera_commands"></div>') }
        if (opts.navigation == true) { fakeHover.append('<div class="camera_prev"><span></span></div>').append('<div class="camera_next"><span></span></div>'); }
        if (opts.thumbnails == true) { wrap.append('<div class="camera_thumbs_cont" />'); }
        if (opts.thumbnails == true && opts.pagination != true) { $('.camera_thumbs_cont', wrap).wrap('<div />').wrap('<div class="camera_thumbs" />').wrap('<div />').wrap('<div class="camera_command_wrap" />'); }
        if (opts.pagination == true) { wrap.append('<div class="camera_pag"></div>'); } wrap.append('<div class="camera_loader"></div>');
        $('.camera_caption', wrap).each(function() { $(this).wrapInner('<div />'); });
        var pieID = 'pie_' + wrap.index(),
            elem = $('.camera_src', wrap),
            target = $('.camera_target', wrap),
            content = $('.camera_target_content', wrap),
            pieContainer = $('.camera_pie', wrap),
            barContainer = $('.camera_bar', wrap),
            prevNav = $('.camera_prev', wrap),
            nextNav = $('.camera_next', wrap),
            commands = $('.camera_commands', wrap),
            pagination = $('.camera_pag', wrap),
            thumbs = $('.camera_thumbs_cont', wrap);
        var w, h;
        var allImg = new Array();
        $('> div', elem).each(function() { allImg.push($(this).attr('data-src')); });
        var allLinks = new Array();
        $('> div', elem).each(function() { if ($(this).attr('data-link')) { allLinks.push($(this).attr('data-link')); } else { allLinks.push(''); } });
        var allTargets = new Array();
        $('> div', elem).each(function() { if ($(this).attr('data-target')) { allTargets.push($(this).attr('data-target')); } else { allTargets.push(''); } });
        var allPor = new Array();
        $('> div', elem).each(function() { if ($(this).attr('data-portrait')) { allPor.push($(this).attr('data-portrait')); } else { allPor.push(''); } });
        var allAlign = new Array();
        $('> div', elem).each(function() { if ($(this).attr('data-alignment')) { allAlign.push($(this).attr('data-alignment')); } else { allAlign.push(''); } });
        var allThumbs = new Array();
        $('> div', elem).each(function() { if ($(this).attr('data-thumb')) { allThumbs.push($(this).attr('data-thumb')); } else { allThumbs.push(''); } });
        var amountSlide = allImg.length;
        $(content).append('<div class="cameraContents" />');
        var loopMove;
        for (loopMove = 0; loopMove < amountSlide; loopMove++) { $('.cameraContents', content).append('<div class="cameraContent" />'); if (allLinks[loopMove] != '') { var dataBox = $('> div ', elem).eq(loopMove).attr('data-box'); if (typeof dataBox !== 'undefined' && dataBox !== false && dataBox != '') { dataBox = 'data-box="' + $('> div ', elem).eq(loopMove).attr('data-box') + '"'; } else { dataBox = ''; } $('.camera_target_content .cameraContent:eq(' + loopMove + ')', wrap).append('<a class="camera_link" href="' + allLinks[loopMove] + '" ' + dataBox + ' target="' + allTargets[loopMove] + '"></a>'); } } $('.camera_caption', wrap).each(function() { var ind = $(this).parent().index(),
                cont = wrap.find('.cameraContent').eq(ind);
            $(this).appendTo(cont); });
        target.append('<div class="cameraCont" />');
        var cameraCont = $('.cameraCont', wrap);
        var loop;
        for (loop = 0; loop < amountSlide; loop++) { cameraCont.append('<div class="cameraSlide cameraSlide_' + loop + '" />'); var div = $('> div:eq(' + loop + ')', elem);
            target.find('.cameraSlide_' + loop).clone(div); }

        function thumbnailVisible() { var wTh = $(thumbs).width();
            $('li', thumbs).removeClass('camera_visThumb');
            $('li', thumbs).each(function() { var pos = $(this).position(),
                    ulW = $('ul', thumbs).outerWidth(),
                    offUl = $('ul', thumbs).offset().left,
                    offDiv = $('> div', thumbs).offset().left,
                    ulLeft = offDiv - offUl; if (ulLeft > 0) { $('.camera_prevThumbs', camera_thumbs_wrap).removeClass('hideNav'); } else { $('.camera_prevThumbs', camera_thumbs_wrap).addClass('hideNav'); } if ((ulW - ulLeft) > wTh) { $('.camera_nextThumbs', camera_thumbs_wrap).removeClass('hideNav'); } else { $('.camera_nextThumbs', camera_thumbs_wrap).addClass('hideNav'); } var left = pos.left,
                    right = pos.left + ($(this).width()); if (right - ulLeft <= wTh && left - ulLeft >= 0) { $(this).addClass('camera_visThumb'); } }); } $(window).bind('load resize pageshow', function() { thumbnailPos();
            thumbnailVisible(); });
        cameraCont.append('<div class="cameraSlide cameraSlide_' + loop + '" />');
        var started;
        wrap.show();
        var w = target.width();
        var h = target.height();
        var setPause;
        $(window).bind('resize pageshow', function() {
            if (started == true) { resizeImage(); } $('ul', thumbs).animate({ 'margin-top': 0 }, 0, thumbnailPos);
            if (!elem.hasClass('paused')) {
                elem.addClass('paused');
                if ($('.camera_stop', camera_thumbs_wrap).length) {
                    $('.camera_stop', camera_thumbs_wrap).hide()
                    $('.camera_play', camera_thumbs_wrap).show();
                    if (loader != 'none') { $('#' + pieID).hide(); }
                } else { if (loader != 'none') { $('#' + pieID).hide(); } } clearTimeout(setPause);
                setPause = setTimeout(function() { elem.removeClass('paused'); if ($('.camera_play', camera_thumbs_wrap).length) { $('.camera_play', camera_thumbs_wrap).hide();
                        $('.camera_stop', camera_thumbs_wrap).show(); if (loader != 'none') { $('#' + pieID).fadeIn(); } } else { if (loader != 'none') { $('#' + pieID).fadeIn(); } } }, 1500);
            }
        });

        function resizeImage() { var res;

            function resizeImageWork() { w = wrap.width(); if (opts.height.indexOf('%') != -1) { var startH = Math.round(w / (100 / parseFloat(opts.height))); if (opts.minHeight != '' && startH < parseFloat(opts.minHeight)) { h = parseFloat(opts.minHeight); } else { h = startH; } wrap.css({ height: h }); } else if (opts.height == 'auto') { h = wrap.height(); } else { h = parseFloat(opts.height);
                    wrap.css({ height: h }); } $('.camerarelative', target).css({ 'width': w, 'height': h });
                $('.imgLoaded', target).each(function() { var t = $(this),
                        wT = t.attr('width'),
                        hT = t.attr('height'),
                        imgLoadIn = t.index(),
                        mTop, mLeft, alignment = t.attr('data-alignment'),
                        portrait = t.attr('data-portrait'); if (typeof alignment === 'undefined' || alignment === false || alignment === '') { alignment = opts.alignment; } if (typeof portrait === 'undefined' || portrait === false || portrait === '') { portrait = opts.portrait; } if (portrait == false || portrait == 'false') { if ((wT / hT) < (w / h)) { var r = w / wT; var d = (Math.abs(h - (hT * r))) * 0.5; switch (alignment) {
                                case 'topLeft':
                                    mTop = 0; break;
                                case 'topCenter':
                                    mTop = 0; break;
                                case 'topRight':
                                    mTop = 0; break;
                                case 'centerLeft':
                                    mTop = '-' + d + 'px'; break;
                                case 'center':
                                    mTop = '-' + d + 'px'; break;
                                case 'centerRight':
                                    mTop = '-' + d + 'px'; break;
                                case 'bottomLeft':
                                    mTop = '-' + d * 2 + 'px'; break;
                                case 'bottomCenter':
                                    mTop = '-' + d * 2 + 'px'; break;
                                case 'bottomRight':
                                    mTop = '-' + d * 2 + 'px'; break; } t.css({ 'height': hT * r, 'margin-left': 0, 'margin-top': mTop, 'position': 'absolute', 'visibility': 'visible', 'width': w }); } else { var r = h / hT; var d = (Math.abs(w - (wT * r))) * 0.5; switch (alignment) {
                                case 'topLeft':
                                    mLeft = 0; break;
                                case 'topCenter':
                                    mLeft = '-' + d + 'px'; break;
                                case 'topRight':
                                    mLeft = '-' + d * 2 + 'px'; break;
                                case 'centerLeft':
                                    mLeft = 0; break;
                                case 'center':
                                    mLeft = '-' + d + 'px'; break;
                                case 'centerRight':
                                    mLeft = '-' + d * 2 + 'px'; break;
                                case 'bottomLeft':
                                    mLeft = 0; break;
                                case 'bottomCenter':
                                    mLeft = '-' + d + 'px'; break;
                                case 'bottomRight':
                                    mLeft = '-' + d * 2 + 'px'; break; } t.css({ 'height': h, 'margin-left': mLeft, 'margin-top': 0, 'position': 'absolute', 'visibility': 'visible', 'width': wT * r }); } } else { if ((wT / hT) < (w / h)) { var r = h / hT; var d = (Math.abs(w - (wT * r))) * 0.5; switch (alignment) {
                                case 'topLeft':
                                    mLeft = 0; break;
                                case 'topCenter':
                                    mLeft = d + 'px'; break;
                                case 'topRight':
                                    mLeft = d * 2 + 'px'; break;
                                case 'centerLeft':
                                    mLeft = 0; break;
                                case 'center':
                                    mLeft = d + 'px'; break;
                                case 'centerRight':
                                    mLeft = d * 2 + 'px'; break;
                                case 'bottomLeft':
                                    mLeft = 0; break;
                                case 'bottomCenter':
                                    mLeft = d + 'px'; break;
                                case 'bottomRight':
                                    mLeft = d * 2 + 'px'; break; } t.css({ 'height': h, 'margin-left': mLeft, 'margin-top': 0, 'position': 'absolute', 'visibility': 'visible', 'width': wT * r }); } else { var r = w / wT; var d = (Math.abs(h - (hT * r))) * 0.5; switch (alignment) {
                                case 'topLeft':
                                    mTop = 0; break;
                                case 'topCenter':
                                    mTop = 0; break;
                                case 'topRight':
                                    mTop = 0; break;
                                case 'centerLeft':
                                    mTop = d + 'px'; break;
                                case 'center':
                                    mTop = d + 'px'; break;
                                case 'centerRight':
                                    mTop = d + 'px'; break;
                                case 'bottomLeft':
                                    mTop = d * 2 + 'px'; break;
                                case 'bottomCenter':
                                    mTop = d * 2 + 'px'; break;
                                case 'bottomRight':
                                    mTop = d * 2 + 'px'; break; } t.css({ 'height': hT * r, 'margin-left': 0, 'margin-top': mTop, 'position': 'absolute', 'visibility': 'visible', 'width': w }); } } }); } if (started == true) { clearTimeout(res);
                res = setTimeout(resizeImageWork, 200); } else { resizeImageWork(); } started = true; }
        var u, setT;
        var clickEv, autoAdv, navHover, commands, pagination;
        var videoHover, videoPresent;
        if (isMobile() && opts.mobileAutoAdvance != '') { autoAdv = opts.mobileAutoAdvance; } else { autoAdv = opts.autoAdvance; }
        if (autoAdv == false) { elem.addClass('paused'); }
        if (isMobile() && opts.mobileNavHover != '') { navHover = opts.mobileNavHover; } else { navHover = opts.navigationHover; }
        if (elem.length != 0) {
            var selector = $('.cameraSlide', target);
            selector.wrapInner('<div class="camerarelative" />');
            var navSlide;
            var barDirection = opts.barDirection;
            var camera_thumbs_wrap = wrap;
            $('iframe', fakeHover).each(function() { var t = $(this); var src = t.attr('src');
                t.attr('data-src', src); var divInd = t.parent().index('.camera_src > div');
                $('.camera_target_content .cameraContent:eq(' + divInd + ')', wrap).append(t); });

            function imgFake() { $('iframe', fakeHover).each(function() { $('.camera_caption', fakeHover).show(); var t = $(this); var cloneSrc = t.attr('data-src');
                    t.attr('src', cloneSrc); var imgFakeUrl = opts.imagePath + 'blank.gif'; var imgFake = new Image();
                    imgFake.src = imgFakeUrl; if (opts.height.indexOf('%') != -1) { var startH = Math.round(w / (100 / parseFloat(opts.height))); if (opts.minHeight != '' && startH < parseFloat(opts.minHeight)) { h = parseFloat(opts.minHeight); } else { h = startH; } } else if (opts.height == 'auto') { h = wrap.height(); } else { h = parseFloat(opts.height); } t.after($(imgFake).attr({ 'class': 'imgFake', 'width': w, 'height': h })); var clone = t.clone();
                    t.remove();
                    $(imgFake).bind('click', function() { if ($(this).css('position') == 'absolute') { $(this).remove(); if (cloneSrc.indexOf('vimeo') != -1 || cloneSrc.indexOf('youtube') != -1) { if (cloneSrc.indexOf('?') != -1) { autoplay = '&autoplay=1'; } else { autoplay = '?autoplay=1'; } } else if (cloneSrc.indexOf('dailymotion') != -1) { if (cloneSrc.indexOf('?') != -1) { autoplay = '&autoPlay=1'; } else { autoplay = '?autoPlay=1'; } } clone.attr('src', cloneSrc + autoplay);
                            videoPresent = true; } else { $(this).css({ position: 'absolute', top: 0, left: 0, zIndex: 10 }).after(clone);
                            clone.css({ position: 'absolute', top: 0, left: 0, zIndex: 9 }); } }); }); } imgFake();
            if (opts.hover == true) { if (!isMobile()) { fakeHover.hover(function() { elem.addClass('hovered'); }, function() { elem.removeClass('hovered'); }); } }
            if (navHover == true) { $(prevNav, wrap).animate({ opacity: 0 }, 0);
                $(nextNav, wrap).animate({ opacity: 0 }, 0);
                $(commands, wrap).animate({ opacity: 0 }, 0); if (isMobile()) { fakeHover.on('vmouseover', function() { $(prevNav, wrap).animate({ opacity: 0.85 }, 200);
                        $(nextNav, wrap).animate({ opacity: 0.85 }, 200);
                        $(commands, wrap).animate({ opacity: 0.85 }, 200); });
                    fakeHover.on('vmouseout', function() { $(prevNav, wrap).delay(500).animate({ opacity: 0 }, 200);
                        $(nextNav, wrap).delay(500).animate({ opacity: 0 }, 200);
                        $(commands, wrap).delay(500).animate({ opacity: 0 }, 200); }); } else { fakeHover.hover(function() { $(prevNav, wrap).animate({ opacity: 0.85 }, 200);
                        $(nextNav, wrap).animate({ opacity: 0.85 }, 200);
                        $(commands, wrap).animate({ opacity: 0.85 }, 200); }, function() { $(prevNav, wrap).animate({ opacity: 0 }, 200);
                        $(nextNav, wrap).animate({ opacity: 0 }, 200);
                        $(commands, wrap).animate({ opacity: 0 }, 200); }); } } $('.camera_stop', camera_thumbs_wrap).on('click', function() {
                autoAdv = false;
                elem.addClass('paused');
                if ($('.camera_stop', camera_thumbs_wrap).length) {
                    $('.camera_stop', camera_thumbs_wrap).hide()
                    $('.camera_play', camera_thumbs_wrap).show();
                    if (loader != 'none') { $('#' + pieID).hide(); }
                } else { if (loader != 'none') { $('#' + pieID).hide(); } }
            });
            $('.camera_play', camera_thumbs_wrap).on('click', function() { autoAdv = true;
                elem.removeClass('paused'); if ($('.camera_play', camera_thumbs_wrap).length) { $('.camera_play', camera_thumbs_wrap).hide();
                    $('.camera_stop', camera_thumbs_wrap).show(); if (loader != 'none') { $('#' + pieID).show(); } } else { if (loader != 'none') { $('#' + pieID).show(); } } });
            if (opts.pauseOnClick == true) {
                $('.camera_target_content', fakeHover).mouseup(function() {
                    autoAdv = false;
                    elem.addClass('paused');
                    $('.camera_stop', camera_thumbs_wrap).hide()
                    $('.camera_play', camera_thumbs_wrap).show();
                    $('#' + pieID).hide();
                });
            }
            $('.cameraContent, .imgFake', fakeHover).hover(function() { videoHover = true; }, function() { videoHover = false; });
            $('.cameraContent, .imgFake', fakeHover).bind('click', function() {
                if (videoPresent == true && videoHover == true) {
                    autoAdv = false;
                    //$('.camera_caption', fakeHover).hide();
                    elem.addClass('paused');
                    $('.camera_stop', camera_thumbs_wrap).hide()
                    $('.camera_play', camera_thumbs_wrap).show();
                    $('#' + pieID).hide();
                }
            });
        }

        function shuffle(arr) { for (var j, x, i = arr.length; i; j = parseInt(Math.random() * i), x = arr[--i], arr[i] = arr[j], arr[j] = x); return arr; }

        function isInteger(s) { return Math.ceil(s) == Math.floor(s); }
        if (loader != 'pie') { barContainer.append('<span class="camera_bar_cont" />');
            $('.camera_bar_cont', barContainer).animate({ opacity: opts.loaderOpacity }, 0).css({ 'position': 'absolute', 'left': 0, 'right': 0, 'top': 0, 'bottom': 0, 'background-color': opts.loaderBgColor }).append('<span id="' + pieID + '" />');
            $('#' + pieID).animate({ opacity: 0 }, 0); var canvas = $('#' + pieID);
            canvas.css({ 'position': 'absolute', 'background-color': opts.loaderColor }); switch (opts.barPosition) {
                case 'left':
                    barContainer.css({ right: 'auto', width: opts.loaderStroke }); break;
                case 'right':
                    barContainer.css({ left: 'auto', width: opts.loaderStroke }); break;
                case 'top':
                    barContainer.css({ bottom: 'auto', height: opts.loaderStroke }); break;
                case 'bottom':
                    barContainer.css({ top: 'auto', height: opts.loaderStroke }); break; } switch (barDirection) {
                case 'leftToRight':
                    canvas.css({ 'left': 0, 'right': 0, 'top': opts.loaderPadding, 'bottom': opts.loaderPadding }); break;
                case 'rightToLeft':
                    canvas.css({ 'left': 0, 'right': 0, 'top': opts.loaderPadding, 'bottom': opts.loaderPadding }); break;
                case 'topToBottom':
                    canvas.css({ 'left': opts.loaderPadding, 'right': opts.loaderPadding, 'top': 0, 'bottom': 0 }); break;
                case 'bottomToTop':
                    canvas.css({ 'left': opts.loaderPadding, 'right': opts.loaderPadding, 'top': 0, 'bottom': 0 }); break; } } else { pieContainer.append('<canvas id="' + pieID + '"></canvas>'); var G_vmlCanvasManager; var canvas = document.getElementById(pieID);
            canvas.setAttribute("width", opts.pieDiameter);
            canvas.setAttribute("height", opts.pieDiameter); var piePosition; switch (opts.piePosition) {
                case 'leftTop':
                    piePosition = 'left:0; top:0;'; break;
                case 'rightTop':
                    piePosition = 'right:0; top:0;'; break;
                case 'leftBottom':
                    piePosition = 'left:0; bottom:0;'; break;
                case 'rightBottom':
                    piePosition = 'right:0; bottom:0;'; break; } canvas.setAttribute("style", "position:absolute; z-index:1002; " + piePosition); var rad; var radNew; if (canvas && canvas.getContext) { var ctx = canvas.getContext("2d");
                ctx.rotate(Math.PI * (3 / 2));
                ctx.translate(-opts.pieDiameter, 0); } }
        if (loader == 'none' || autoAdv == false) { $('#' + pieID).hide();
            $('.camera_canvas_wrap', camera_thumbs_wrap).hide(); }
        if ($(pagination).length) { $(pagination).append('<ul class="camera_pag_ul" />'); var li; for (li = 0; li < amountSlide; li++) { $('.camera_pag_ul', wrap).append('<li class="pag_nav_' + li + '" style="position:relative; z-index:1002"><span><span>' + li + '</span></span></li>'); } $('.camera_pag_ul li', wrap).hover(function() { $(this).addClass('camera_hover'); if ($('.camera_thumb', this).length) { var wTh = $('.camera_thumb', this).outerWidth(),
                        hTh = $('.camera_thumb', this).outerHeight(),
                        wTt = $(this).outerWidth();
                    $('.camera_thumb', this).show().css({ 'top': '-' + hTh + 'px', 'left': '-' + (wTh - wTt) / 2 + 'px' }).animate({ 'opacity': 1, 'margin-top': '-3px' }, 200);
                    $('.thumb_arrow', this).show().animate({ 'opacity': 1, 'margin-top': '-3px' }, 200); } }, function() { $(this).removeClass('camera_hover');
                $('.camera_thumb', this).animate({ 'margin-top': '-20px', 'opacity': 0 }, 200, function() { $(this).css({ marginTop: '5px' }).hide(); });
                $('.thumb_arrow', this).animate({ 'margin-top': '-20px', 'opacity': 0 }, 200, function() { $(this).css({ marginTop: '5px' }).hide(); }); }); }
        if ($(thumbs).length) { var thumbUrl; if (!$(pagination).length) { $(thumbs).append('<div />');
                $(thumbs).before('<div class="camera_prevThumbs hideNav"><div></div></div>').before('<div class="camera_nextThumbs hideNav"><div></div></div>');
                $('> div', thumbs).append('<ul />');
                $.each(allThumbs, function(i, val) { if ($('> div', elem).eq(i).attr('data-thumb') != '') { var thumbUrl = $('> div', elem).eq(i).attr('data-thumb'),
                            newImg = new Image();
                        newImg.src = thumbUrl;
                        $('ul', thumbs).append('<li class="pix_thumb pix_thumb_' + i + '" />');
                        $('li.pix_thumb_' + i, thumbs).append($(newImg).attr('class', 'camera_thumb')); } }); } else { $.each(allThumbs, function(i, val) { if ($('> div', elem).eq(i).attr('data-thumb') != '') { var thumbUrl = $('> div', elem).eq(i).attr('data-thumb'),
                            newImg = new Image();
                        newImg.src = thumbUrl;
                        $('li.pag_nav_' + i, pagination).append($(newImg).attr('class', 'camera_thumb').css({ 'position': 'absolute' }).animate({ opacity: 0 }, 0));
                        $('li.pag_nav_' + i + ' > img', pagination).after('<div class="thumb_arrow" />');
                        $('li.pag_nav_' + i + ' > .thumb_arrow', pagination).animate({ opacity: 0 }, 0); } });
                wrap.css({ marginBottom: $(pagination).outerHeight() }); } } else if (!$(thumbs).length && $(pagination).length) { wrap.css({ marginBottom: $(pagination).outerHeight() }); }
        var firstPos = true;

        function thumbnailPos() { if ($(thumbs).length && !$(pagination).length) { var wTh = $(thumbs).outerWidth(),
                    owTh = $('ul > li', thumbs).outerWidth(),
                    pos = $('li.cameracurrent', thumbs).length ? $('li.cameracurrent', thumbs).position() : '',
                    ulW = ($('ul > li', thumbs).length * $('ul > li', thumbs).outerWidth()),
                    offUl = $('ul', thumbs).offset().left,
                    offDiv = $('> div', thumbs).offset().left,
                    ulLeft; if (offUl < 0) { ulLeft = '-' + (offDiv - offUl); } else { ulLeft = offDiv - offUl; } if (firstPos == true) { $('ul', thumbs).width($('ul > li', thumbs).length * $('ul > li', thumbs).outerWidth()); if ($(thumbs).length && !$(pagination).lenght) { wrap.css({ marginBottom: $(thumbs).outerHeight() }); } thumbnailVisible();
                    $('ul', thumbs).width($('ul > li', thumbs).length * $('ul > li', thumbs).outerWidth()); if ($(thumbs).length && !$(pagination).lenght) { wrap.css({ marginBottom: $(thumbs).outerHeight() }); } } firstPos = false; var left = $('li.cameracurrent', thumbs).length ? pos.left : '',
                    right = $('li.cameracurrent', thumbs).length ? pos.left + ($('li.cameracurrent', thumbs).outerWidth()) : ''; if (left < $('li.cameracurrent', thumbs).outerWidth()) { left = 0; } if (right - ulLeft > wTh) { if ((left + wTh) < ulW) { $('ul', thumbs).animate({ 'margin-left': '-' + (left) + 'px' }, 500, thumbnailVisible); } else { $('ul', thumbs).animate({ 'margin-left': '-' + ($('ul', thumbs).outerWidth() - wTh) + 'px' }, 500, thumbnailVisible); } } else if (left - ulLeft < 0) { $('ul', thumbs).animate({ 'margin-left': '-' + (left) + 'px' }, 500, thumbnailVisible); } else { $('ul', thumbs).css({ 'margin-left': 'auto', 'margin-right': 'auto' });
                    setTimeout(thumbnailVisible, 100); } } }
        if ($(commands).length) { $(commands).append('<div class="camera_play"></div>').append('<div class="camera_stop"></div>'); if (autoAdv == true) { $('.camera_play', camera_thumbs_wrap).hide();
                $('.camera_stop', camera_thumbs_wrap).show(); } else { $('.camera_stop', camera_thumbs_wrap).hide();
                $('.camera_play', camera_thumbs_wrap).show(); } }

        function canvasLoader() { rad = 0; var barWidth = $('.camera_bar_cont', camera_thumbs_wrap).width(),
                barHeight = $('.camera_bar_cont', camera_thumbs_wrap).height(); if (loader != 'pie') { switch (barDirection) {
                    case 'leftToRight':
                        $('#' + pieID).css({ 'right': barWidth }); break;
                    case 'rightToLeft':
                        $('#' + pieID).css({ 'left': barWidth }); break;
                    case 'topToBottom':
                        $('#' + pieID).css({ 'bottom': barHeight }); break;
                    case 'bottomToTop':
                        $('#' + pieID).css({ 'top': barHeight }); break; } } else { ctx.clearRect(0, 0, opts.pieDiameter, opts.pieDiameter); } } canvasLoader();
        $('.moveFromLeft, .moveFromRight, .moveFromTop, .moveFromBottom, .fadeIn, .fadeFromLeft, .fadeFromRight, .fadeFromTop, .fadeFromBottom', fakeHover).each(function() { $(this).css('visibility', 'hidden'); });
        opts.onStartLoading.call(this);
        nextSlide();

        function nextSlide(navSlide) { elem.addClass('camerasliding');
            videoPresent = false; var vis = parseFloat($('div.cameraSlide.cameracurrent', target).index()); if (navSlide > 0) { var slideI = navSlide - 1; } else if (vis == amountSlide - 1) { var slideI = 0; } else { var slideI = vis + 1; } var slide = $('.cameraSlide:eq(' + slideI + ')', target); var slideNext = $('.cameraSlide:eq(' + (slideI + 1) + ')', target).addClass('cameranext'); if (vis != slideI + 1) { slideNext.hide(); } $('.cameraContent.cameracurrent').animate({ left: 370 }, { duration: 600 }).fadeOut(600);;
            $('.camera_caption', fakeHover).show();
            $('.camerarelative', slide).append($('> div ', elem).eq(slideI).find('> div.camera_effected'));
            $('.camera_target_content .cameraContent:eq(' + slideI + ')', wrap).append($('> div ', elem).eq(slideI).find('> div')); if (!$('.imgLoaded', slide).length) { var imgUrl = allImg[slideI]; var imgLoaded = new Image();
                imgLoaded.src = imgUrl + "?" + new Date().getTime();
                slide.css('visibility', 'hidden');
                slide.prepend($(imgLoaded).attr('class', 'imgLoaded').css('visibility', 'hidden')); var wT, hT; if (!$(imgLoaded).get(0).complete || wT == '0' || hT == '0' || typeof wT === 'undefined' || wT === false || typeof hT === 'undefined' || hT === false) { $('.camera_loader', wrap).delay(500).fadeIn(400);
                    imgLoaded.onload = function() { wT = imgLoaded.naturalWidth;
                        hT = imgLoaded.naturalHeight;
                        $(imgLoaded).attr('data-alignment', allAlign[slideI]).attr('data-portrait', allPor[slideI]);
                        $(imgLoaded).attr('width', wT);
                        $(imgLoaded).attr('height', hT);
                        target.find('.cameraSlide_' + slideI).hide().css('visibility', 'visible');
                        resizeImage();
                        nextSlide(slideI + 1); }; } } else { if (allImg.length > (slideI + 1) && !$('.imgLoaded', slideNext).length) { var imgUrl2 = allImg[(slideI + 1)]; var imgLoaded2 = new Image();
                    imgLoaded2.src = imgUrl2 + "?" + new Date().getTime();
                    slideNext.prepend($(imgLoaded2).attr('class', 'imgLoaded').css('visibility', 'hidden'));
                    imgLoaded2.onload = function() { wT = imgLoaded2.naturalWidth;
                        hT = imgLoaded2.naturalHeight;
                        $(imgLoaded2).attr('data-alignment', allAlign[slideI + 1]).attr('data-portrait', allPor[slideI + 1]);
                        $(imgLoaded2).attr('width', wT);
                        $(imgLoaded2).attr('height', hT);
                        resizeImage(); }; } opts.onLoaded.call(this); if ($('.camera_loader', wrap).is(':visible')) { $('.camera_loader', wrap).fadeOut(400); } else { $('.camera_loader', wrap).css({ 'visibility': 'hidden' });
                    $('.camera_loader', wrap).fadeOut(400, function() { $('.camera_loader', wrap).css({ 'visibility': 'visible' }); }); } var rows = opts.rows,
                    cols = opts.cols,
                    couples = 1,
                    difference = 0,
                    dataSlideOn, time, transPeriod, fx, easing, randomFx = new Array('simpleFade', 'curtainTopLeft', 'curtainTopRight', 'curtainBottomLeft', 'curtainBottomRight', 'curtainSliceLeft', 'curtainSliceRight', 'blindCurtainTopLeft', 'blindCurtainTopRight', 'blindCurtainBottomLeft', 'blindCurtainBottomRight', 'blindCurtainSliceBottom', 'blindCurtainSliceTop', 'stampede', 'mosaic', 'mosaicReverse', 'mosaicRandom', 'mosaicSpiral', 'mosaicSpiralReverse', 'topLeftBottomRight', 'bottomRightTopLeft', 'bottomLeftTopRight', 'topRightBottomLeft', 'scrollLeft', 'scrollRight', 'scrollTop', 'scrollBottom', 'scrollHorz');
                marginLeft = 0, marginTop = 0, opacityOnGrid = 0; if (opts.opacityOnGrid == true) { opacityOnGrid = 0; } else { opacityOnGrid = 1; } var dataFx = $(' > div', elem).eq(slideI).attr('data-fx'); if (isMobile() && opts.mobileFx != '' && opts.mobileFx != 'default') { fx = opts.mobileFx; } else { if (typeof dataFx !== 'undefined' && dataFx !== false && dataFx !== 'default') { fx = dataFx; } else { fx = opts.fx; } } if (fx == 'random') { fx = shuffle(randomFx);
                    fx = fx[0]; } else { fx = fx; if (fx.indexOf(',') > 0) { fx = fx.replace(/ /g, '');
                        fx = fx.split(',');
                        fx = shuffle(fx);
                        fx = fx[0]; } } dataEasing = $(' > div', elem).eq(slideI).attr('data-easing');
                mobileEasing = $(' > div', elem).eq(slideI).attr('data-mobileEasing'); if (isMobile() && opts.mobileEasing != '' && opts.mobileEasing != 'default') { if (typeof mobileEasing !== 'undefined' && mobileEasing !== false && mobileEasing !== 'default') { easing = mobileEasing; } else { easing = opts.mobileEasing; } } else { if (typeof dataEasing !== 'undefined' && dataEasing !== false && dataEasing !== 'default') { easing = dataEasing; } else { easing = opts.easing; } } dataSlideOn = $(' > div', elem).eq(slideI).attr('data-slideOn'); if (typeof dataSlideOn !== 'undefined' && dataSlideOn !== false) { slideOn = dataSlideOn; } else { if (opts.slideOn == 'random') { var slideOn = new Array('next', 'prev');
                        slideOn = shuffle(slideOn);
                        slideOn = slideOn[0]; } else { slideOn = opts.slideOn; } } var dataTime = $(' > div', elem).eq(slideI).attr('data-time'); if (typeof dataTime !== 'undefined' && dataTime !== false && dataTime !== '') { time = parseFloat(dataTime); } else { time = opts.time; } var dataTransPeriod = $(' > div', elem).eq(slideI).attr('data-transPeriod'); if (typeof dataTransPeriod !== 'undefined' && dataTransPeriod !== false && dataTransPeriod !== '') { transPeriod = parseFloat(dataTransPeriod); } else { transPeriod = opts.transPeriod; } if (!$(elem).hasClass('camerastarted')) { fx = 'simpleFade';
                    slideOn = 'next';
                    easing = '';
                    transPeriod = 400;
                    $(elem).addClass('camerastarted') } switch (fx) {
                    case 'simpleFade':
                        cols = 1;
                        rows = 1; break;
                    case 'curtainTopLeft':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'curtainTopRight':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'curtainBottomLeft':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'curtainBottomRight':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'curtainSliceLeft':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'curtainSliceRight':
                        if (opts.slicedCols == 0) { cols = opts.cols; } else { cols = opts.slicedCols; } rows = 1; break;
                    case 'blindCurtainTopLeft':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'blindCurtainTopRight':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'blindCurtainBottomLeft':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'blindCurtainBottomRight':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'blindCurtainSliceTop':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'blindCurtainSliceBottom':
                        if (opts.slicedRows == 0) { rows = opts.rows; } else { rows = opts.slicedRows; } cols = 1; break;
                    case 'stampede':
                        difference = '-' + transPeriod; break;
                    case 'mosaic':
                        difference = opts.gridDifference; break;
                    case 'mosaicReverse':
                        difference = opts.gridDifference; break;
                    case 'mosaicRandom':
                        break;
                    case 'mosaicSpiral':
                        difference = opts.gridDifference;
                        couples = 1.7; break;
                    case 'mosaicSpiralReverse':
                        difference = opts.gridDifference;
                        couples = 1.7; break;
                    case 'topLeftBottomRight':
                        difference = opts.gridDifference;
                        couples = 6; break;
                    case 'bottomRightTopLeft':
                        difference = opts.gridDifference;
                        couples = 6; break;
                    case 'bottomLeftTopRight':
                        difference = opts.gridDifference;
                        couples = 6; break;
                    case 'topRightBottomLeft':
                        difference = opts.gridDifference;
                        couples = 6; break;
                    case 'scrollLeft':
                        cols = 1;
                        rows = 1; break;
                    case 'scrollRight':
                        cols = 1;
                        rows = 1; break;
                    case 'scrollTop':
                        cols = 1;
                        rows = 1; break;
                    case 'scrollBottom':
                        cols = 1;
                        rows = 1; break;
                    case 'scrollHorz':
                        cols = 1;
                        rows = 1; break; } var cycle = 0; var blocks = rows * cols; var leftScrap = w - (Math.floor(w / cols) * cols); var topScrap = h - (Math.floor(h / rows) * rows); var addLeft; var addTop; var tAppW = 0; var tAppH = 0; var arr = new Array(); var delay = new Array(); var order = new Array(); while (cycle < blocks) { arr.push(cycle);
                    delay.push(cycle);
                    cameraCont.append('<div class="cameraappended" style="display:none; overflow:hidden; position:absolute; z-index:1000" />'); var tApp = $('.cameraappended:eq(' + cycle + ')', target); if (fx == 'scrollLeft' || fx == 'scrollRight' || fx == 'scrollTop' || fx == 'scrollBottom' || fx == 'scrollHorz') { selector.eq(slideI).clone().show().appendTo(tApp); } else { if (slideOn == 'next') { selector.eq(slideI).clone().show().appendTo(tApp); } else { selector.eq(vis).clone().show().appendTo(tApp); } } if (cycle % cols < leftScrap) { addLeft = 1; } else { addLeft = 0; } if (cycle % cols == 0) { tAppW = 0; } if (Math.floor(cycle / cols) < topScrap) { addTop = 1; } else { addTop = 0; } tApp.css({ 'height': Math.floor((h / rows) + addTop + 1), 'left': tAppW, 'top': tAppH, 'width': Math.floor((w / cols) + addLeft + 1) });
                    $('> .cameraSlide', tApp).css({ 'height': h, 'margin-left': '-' + tAppW + 'px', 'margin-top': '-' + tAppH + 'px', 'width': w });
                    tAppW = tAppW + tApp.width() - 1; if (cycle % cols == cols - 1) { tAppH = tAppH + tApp.height() - 1; } cycle++; } switch (fx) {
                    case 'curtainTopLeft':
                        break;
                    case 'curtainBottomLeft':
                        break;
                    case 'curtainSliceLeft':
                        break;
                    case 'curtainTopRight':
                        arr = arr.reverse(); break;
                    case 'curtainBottomRight':
                        arr = arr.reverse(); break;
                    case 'curtainSliceRight':
                        arr = arr.reverse(); break;
                    case 'blindCurtainTopLeft':
                        break;
                    case 'blindCurtainBottomLeft':
                        arr = arr.reverse(); break;
                    case 'blindCurtainSliceTop':
                        break;
                    case 'blindCurtainTopRight':
                        break;
                    case 'blindCurtainBottomRight':
                        arr = arr.reverse(); break;
                    case 'blindCurtainSliceBottom':
                        arr = arr.reverse(); break;
                    case 'stampede':
                        arr = shuffle(arr); break;
                    case 'mosaic':
                        break;
                    case 'mosaicReverse':
                        arr = arr.reverse(); break;
                    case 'mosaicRandom':
                        arr = shuffle(arr); break;
                    case 'mosaicSpiral':
                        var rows2 = rows / 2,
                            x, y, z, n = 0; for (z = 0; z < rows2; z++) { y = z; for (x = z; x < cols - z - 1; x++) { order[n++] = y * cols + x; } x = cols - z - 1; for (y = z; y < rows - z - 1; y++) { order[n++] = y * cols + x; } y = rows - z - 1; for (x = cols - z - 1; x > z; x--) { order[n++] = y * cols + x; } x = z; for (y = rows - z - 1; y > z; y--) { order[n++] = y * cols + x; } } arr = order; break;
                    case 'mosaicSpiralReverse':
                        var rows2 = rows / 2,
                            x, y, z, n = blocks - 1; for (z = 0; z < rows2; z++) { y = z; for (x = z; x < cols - z - 1; x++) { order[n--] = y * cols + x; } x = cols - z - 1; for (y = z; y < rows - z - 1; y++) { order[n--] = y * cols + x; } y = rows - z - 1; for (x = cols - z - 1; x > z; x--) { order[n--] = y * cols + x; } x = z; for (y = rows - z - 1; y > z; y--) { order[n--] = y * cols + x; } } arr = order; break;
                    case 'topLeftBottomRight':
                        for (var y = 0; y < rows; y++)
                            for (var x = 0; x < cols; x++) { order.push(x + y); } delay = order; break;
                    case 'bottomRightTopLeft':
                        for (var y = 0; y < rows; y++)
                            for (var x = 0; x < cols; x++) { order.push(x + y); } delay = order.reverse(); break;
                    case 'bottomLeftTopRight':
                        for (var y = rows; y > 0; y--)
                            for (var x = 0; x < cols; x++) { order.push(x + y); } delay = order; break;
                    case 'topRightBottomLeft':
                        for (var y = 0; y < rows; y++)
                            for (var x = cols; x > 0; x--) { order.push(x + y); } delay = order; break; } $.each(arr, function(index, value) { if (value % cols < leftScrap) { addLeft = 1; } else { addLeft = 0; } if (value % cols == 0) { tAppW = 0; } if (Math.floor(value / cols) < topScrap) { addTop = 1; } else { addTop = 0; } switch (fx) {
                        case 'simpleFade':
                            height = h;
                            width = w;
                            opacityOnGrid = 0; break;
                        case 'curtainTopLeft':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1), marginTop = '-' + Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'curtainTopRight':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1), marginTop = '-' + Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'curtainBottomLeft':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1), marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'curtainBottomRight':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1), marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'curtainSliceLeft':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1); if (value % 2 == 0) { marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; } else { marginTop = '-' + Math.floor((h / rows) + addTop + 1) + 'px'; } break;
                        case 'curtainSliceRight':
                            height = 0, width = Math.floor((w / cols) + addLeft + 1); if (value % 2 == 0) { marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; } else { marginTop = '-' + Math.floor((h / rows) + addTop + 1) + 'px'; } break;
                        case 'blindCurtainTopLeft':
                            height = Math.floor((h / rows) + addTop + 1), width = 0, marginLeft = '-' + Math.floor((w / cols) + addLeft + 1) + 'px'; break;
                        case 'blindCurtainTopRight':
                            height = Math.floor((h / rows) + addTop + 1), width = 0, marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px'; break;
                        case 'blindCurtainBottomLeft':
                            height = Math.floor((h / rows) + addTop + 1), width = 0, marginLeft = '-' + Math.floor((w / cols) + addLeft + 1) + 'px'; break;
                        case 'blindCurtainBottomRight':
                            height = Math.floor((h / rows) + addTop + 1), width = 0, marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px'; break;
                        case 'blindCurtainSliceBottom':
                            height = Math.floor((h / rows) + addTop + 1), width = 0; if (value % 2 == 0) { marginLeft = '-' + Math.floor((w / cols) + addLeft + 1) + 'px'; } else { marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px'; } break;
                        case 'blindCurtainSliceTop':
                            height = Math.floor((h / rows) + addTop + 1), width = 0; if (value % 2 == 0) { marginLeft = '-' + Math.floor((w / cols) + addLeft + 1) + 'px'; } else { marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px'; } break;
                        case 'stampede':
                            height = 0;
                            width = 0;
                            marginLeft = (w * 0.2) * (((index) % cols) - (cols - (Math.floor(cols / 2)))) + 'px';
                            marginTop = (h * 0.2) * ((Math.floor(index / cols) + 1) - (rows - (Math.floor(rows / 2)))) + 'px'; break;
                        case 'mosaic':
                            height = 0;
                            width = 0; break;
                        case 'mosaicReverse':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px';
                            marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'mosaicRandom':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) * 0.5 + 'px';
                            marginTop = Math.floor((h / rows) + addTop + 1) * 0.5 + 'px'; break;
                        case 'mosaicSpiral':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) * 0.5 + 'px';
                            marginTop = Math.floor((h / rows) + addTop + 1) * 0.5 + 'px'; break;
                        case 'mosaicSpiralReverse':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) * 0.5 + 'px';
                            marginTop = Math.floor((h / rows) + addTop + 1) * 0.5 + 'px'; break;
                        case 'topLeftBottomRight':
                            height = 0;
                            width = 0; break;
                        case 'bottomRightTopLeft':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px';
                            marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'bottomLeftTopRight':
                            height = 0;
                            width = 0;
                            marginLeft = 0;
                            marginTop = Math.floor((h / rows) + addTop + 1) + 'px'; break;
                        case 'topRightBottomLeft':
                            height = 0;
                            width = 0;
                            marginLeft = Math.floor((w / cols) + addLeft + 1) + 'px';
                            marginTop = 0; break;
                        case 'scrollRight':
                            height = h;
                            width = w;
                            marginLeft = -w; break;
                        case 'scrollLeft':
                            height = h;
                            width = w;
                            marginLeft = w; break;
                        case 'scrollTop':
                            height = h;
                            width = w;
                            marginTop = h; break;
                        case 'scrollBottom':
                            height = h;
                            width = w;
                            marginTop = -h; break;
                        case 'scrollHorz':
                            height = h;
                            width = w; if (vis == 0 && slideI == amountSlide - 1) { marginLeft = -w; } else if (vis < slideI || (vis == amountSlide - 1 && slideI == 0)) { marginLeft = w; } else { marginLeft = -w; } break; } var tApp = $('.cameraappended:eq(' + value + ')', target); if (typeof u !== 'undefined') { clearInterval(u);
                        clearTimeout(setT);
                        setT = setTimeout(canvasLoader, transPeriod + difference); } if ($(pagination).length) { $('.camera_pag li', wrap).removeClass('cameracurrent');
                        $('.camera_pag li', wrap).eq(slideI).addClass('cameracurrent'); } if ($(thumbs).length) { $('li', thumbs).removeClass('cameracurrent');
                        $('li', thumbs).eq(slideI).addClass('cameracurrent');
                        $('li', thumbs).not('.cameracurrent').find('img').animate({ opacity: .5 }, 0);
                        $('li.cameracurrent img', thumbs).animate({ opacity: 0.85 }, 0);
                        $('li', thumbs).hover(function() { $('img', this).stop(true, false).animate({ opacity: 0.85 }, 150); }, function() { if (!$(this).hasClass('cameracurrent')) { $('img', this).stop(true, false).animate({ opacity: .5 }, 150); } }); } var easedTime = parseFloat(transPeriod) + parseFloat(difference);

                    function cameraeased() { $(this).addClass('cameraeased'); if ($('.cameraeased', target).length >= 0) { $(thumbs).css({ visibility: 'visible' }); } if ($('.cameraeased', target).length == blocks) { thumbnailPos();
                            $('.moveFromLeft, .moveFromRight, .moveFromTop, .moveFromBottom, .fadeIn, .fadeFromLeft, .fadeFromRight, .fadeFromTop, .fadeFromBottom', fakeHover).each(function() { $(this).css('visibility', 'hidden'); });
                            selector.eq(slideI).show().css('z-index', '999').removeClass('cameranext').addClass('cameracurrent');
                            selector.eq(vis).css('z-index', '1').removeClass('cameracurrent');
                            $('.cameraContent', fakeHover).eq(slideI).addClass('cameracurrent'); if (vis >= 0) { $('.cameraContent', fakeHover).eq(vis).removeClass('cameracurrent'); } opts.onEndTransition.call(this); if ($('> div', elem).eq(slideI).attr('data-video') != 'hide' && $('.cameraContent.cameracurrent .imgFake', fakeHover).length) { $('.cameraContent.cameracurrent .imgFake', fakeHover).click(); } var lMoveIn = selector.eq(slideI).find('.fadeIn').length; var lMoveInContent = $('.cameraContent', fakeHover).eq(slideI).find('.moveFromLeft, .moveFromRight, .moveFromTop, .moveFromBottom, .fadeIn, .fadeFromLeft, .fadeFromRight, .fadeFromTop, .fadeFromBottom').length; if (lMoveIn != 0) { $('.cameraSlide.cameracurrent .fadeIn', fakeHover).each(function() { if ($(this).attr('data-easing') != '') { var easeMove = $(this).attr('data-easing'); } else { var easeMove = easing; } var t = $(this); if (typeof t.attr('data-outerWidth') === 'undefined' || t.attr('data-outerWidth') === false || t.attr('data-outerWidth') === '') { var wMoveIn = t.outerWidth();
                                        t.attr('data-outerWidth', wMoveIn); } else { var wMoveIn = t.attr('data-outerWidth'); } if (typeof t.attr('data-outerHeight') === 'undefined' || t.attr('data-outerHeight') === false || t.attr('data-outerHeight') === '') { var hMoveIn = t.outerHeight();
                                        t.attr('data-outerHeight', hMoveIn); } else { var hMoveIn = t.attr('data-outerHeight'); } var pos = t.position(); var left = pos.left; var top = pos.top; var tClass = t.attr('class'); var ind = t.index(); var hRel = t.parents('.camerarelative').outerHeight(); var wRel = t.parents('.camerarelative').outerWidth(); if (tClass.indexOf("fadeIn") != -1) { t.animate({ opacity: 0 }, 0).css('visibility', 'visible').delay((time / lMoveIn) * (0.1 * (ind - 1))).animate({ opacity: 0.85 }, (time / lMoveIn) * 0.15, easeMove); } else { t.css('visibility', 'visible'); } }); } $('.cameraContent.cameracurrent').animate({ left: 0 }, { duration: 600 }).show(); if (lMoveInContent != 0) { $('.cameraContent.cameracurrent .moveFromLeft, .cameraContent.cameracurrent .moveFromRight, .cameraContent.cameracurrent .moveFromTop, .cameraContent.cameracurrent .moveFromBottom, .cameraContent.cameracurrent .fadeIn, .cameraContent.cameracurrent .fadeFromLeft, .cameraContent.cameracurrent .fadeFromRight, .cameraContent.cameracurrent .fadeFromTop, .cameraContent.cameracurrent .fadeFromBottom', fakeHover).each(function() { if ($(this).attr('data-easing') != '') { var easeMove = $(this).attr('data-easing'); } else { var easeMove = easing; } var t = $(this); var pos = t.position(); var left = pos.left; var top = pos.top; var tClass = t.attr('class'); var ind = t.index(); var thisH = t.outerHeight(); if (tClass.indexOf("moveFromLeft") != -1) { t.css({ 'left': '-' + (w) + 'px', 'right': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'left': pos.left }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("moveFromRight") != -1) { t.css({ 'left': w + 'px', 'right': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'left': pos.left }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("moveFromTop") != -1) { t.css({ 'top': '-' + h + 'px', 'bottom': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'top': pos.top }, (time / lMoveInContent) * 0.15, easeMove, function() { t.css({ top: 'auto', bottom: 0 }); }); } else if (tClass.indexOf("moveFromBottom") != -1) { t.css({ 'top': h + 'px', 'bottom': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'top': pos.top }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("fadeFromLeft") != -1) { t.animate({ opacity: 0 }, 0).css({ 'left': '-' + (w) + 'px', 'right': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'left': pos.left, opacity: 0.85 }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("fadeFromRight") != -1) { t.animate({ opacity: 0 }, 0).css({ 'left': (w) + 'px', 'right': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'left': pos.left, opacity: 0.85 }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("fadeFromTop") != -1) { t.animate({ opacity: 0 }, 0).css({ 'top': '-' + (h) + 'px', 'bottom': 'auto' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'top': pos.top, opacity: 0.85 }, (time / lMoveInContent) * 0.15, easeMove, function() { t.css({ top: 'auto', bottom: 0 }); }); } else if (tClass.indexOf("fadeFromBottom") != -1) { t.animate({ opacity: 0 }, 0).css({ 'bottom': '-' + thisH + 'px' });
                                        t.css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ 'bottom': '0', opacity: 0.85 }, (time / lMoveInContent) * 0.15, easeMove); } else if (tClass.indexOf("fadeIn") != -1) { t.animate({ opacity: 0 }, 0).css('visibility', 'visible').delay((time / lMoveInContent) * (0.1 * (ind - 1))).animate({ opacity: 0.85 }, (time / lMoveInContent) * 0.15, easeMove); } else { t.css('visibility', 'visible'); } }); } $('.cameraappended', target).remove();
                            elem.removeClass('camerasliding');
                            selector.eq(vis).hide(); var barWidth = $('.camera_bar_cont', camera_thumbs_wrap).width(),
                                barHeight = $('.camera_bar_cont', camera_thumbs_wrap).height(),
                                radSum; if (loader != 'pie') { radSum = 0.05; } else { radSum = 0.005; } $('#' + pieID).animate({ opacity: opts.loaderOpacity }, 200);
                            u = setInterval(function() { if (elem.hasClass('stopped')) { clearInterval(u); } if (loader != 'pie') { if (rad <= 1.002 && !elem.hasClass('stopped') && !elem.hasClass('paused') && !elem.hasClass('hovered')) { rad = (rad + radSum); } else if (rad <= 1 && (elem.hasClass('stopped') || elem.hasClass('paused') || elem.hasClass('stopped') || elem.hasClass('hovered'))) { rad = rad; } else { if (!elem.hasClass('stopped') && !elem.hasClass('paused') && !elem.hasClass('hovered')) { clearInterval(u);
                                            imgFake();
                                            $('#' + pieID).animate({ opacity: 0 }, 200, function() { clearTimeout(setT);
                                                setT = setTimeout(canvasLoader, easedTime);
                                                nextSlide();
                                                opts.onStartLoading.call(this); }); } } switch (barDirection) {
                                        case 'leftToRight':
                                            $('#' + pieID).animate({ 'right': barWidth - (barWidth * rad) }, (time * radSum), 'linear'); break;
                                        case 'rightToLeft':
                                            $('#' + pieID).animate({ 'left': barWidth - (barWidth * rad) }, (time * radSum), 'linear'); break;
                                        case 'topToBottom':
                                            $('#' + pieID).animate({ 'bottom': barHeight - (barHeight * rad) }, (time * radSum), 'linear'); break;
                                        case 'bottomToTop':
                                            $('#' + pieID).animate({ 'bottom': barHeight - (barHeight * rad) }, (time * radSum), 'linear'); break; } } else { radNew = rad;
                                    ctx.clearRect(0, 0, opts.pieDiameter, opts.pieDiameter);
                                    ctx.globalCompositeOperation = 'destination-over';
                                    ctx.beginPath();
                                    ctx.arc((opts.pieDiameter) / 2, (opts.pieDiameter) / 2, (opts.pieDiameter) / 2 - opts.loaderStroke, 0, Math.PI * 2, false);
                                    ctx.lineWidth = opts.loaderStroke;
                                    ctx.strokeStyle = opts.loaderBgColor;
                                    ctx.stroke();
                                    ctx.closePath();
                                    ctx.globalCompositeOperation = 'source-over';
                                    ctx.beginPath();
                                    ctx.arc((opts.pieDiameter) / 2, (opts.pieDiameter) / 2, (opts.pieDiameter) / 2 - opts.loaderStroke, 0, Math.PI * 2 * radNew, false);
                                    ctx.lineWidth = opts.loaderStroke - (opts.loaderPadding * 2);
                                    ctx.strokeStyle = opts.loaderColor;
                                    ctx.stroke();
                                    ctx.closePath(); if (rad <= 1.002 && !elem.hasClass('stopped') && !elem.hasClass('paused') && !elem.hasClass('hovered')) { rad = (rad + radSum); } else if (rad <= 1 && (elem.hasClass('stopped') || elem.hasClass('paused') || elem.hasClass('hovered'))) { rad = rad; } else { if (!elem.hasClass('stopped') && !elem.hasClass('paused') && !elem.hasClass('hovered')) { clearInterval(u);
                                            imgFake();
                                            $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 200, function() { clearTimeout(setT);
                                                setT = setTimeout(canvasLoader, easedTime);
                                                nextSlide();
                                                opts.onStartLoading.call(this); }); } } } }, time * radSum); } } if (fx == 'scrollLeft' || fx == 'scrollRight' || fx == 'scrollTop' || fx == 'scrollBottom' || fx == 'scrollHorz') { opts.onStartTransition.call(this);
                        easedTime = 0;
                        tApp.delay((((transPeriod + difference) / blocks) * delay[index] * couples) * 0.5).css({ 'display': 'block', 'height': height, 'margin-left': marginLeft, 'margin-top': marginTop, 'width': width }).animate({ 'height': Math.floor((h / rows) + addTop + 1), 'margin-top': 0, 'margin-left': 0, 'width': Math.floor((w / cols) + addLeft + 1) }, (transPeriod - difference), easing, cameraeased);
                        selector.eq(vis).delay((((transPeriod + difference) / blocks) * delay[index] * couples) * 0.5).animate({ 'margin-left': marginLeft * (-1), 'margin-top': marginTop * (-1) }, (transPeriod - difference), easing, function() { $(this).css({ 'margin-top': 0, 'margin-left': 0 }); }); } else { opts.onStartTransition.call(this);
                        easedTime = parseFloat(transPeriod) + parseFloat(difference); if (slideOn == 'next') { tApp.delay((((transPeriod + difference) / blocks) * delay[index] * couples) * 0.5).css({ 'display': 'block', 'height': height, 'margin-left': marginLeft, 'margin-top': marginTop, 'width': width, 'opacity': opacityOnGrid }).animate({ 'height': Math.floor((h / rows) + addTop + 1), 'margin-top': 0, 'margin-left': 0, 'opacity': 1, 'width': Math.floor((w / cols) + addLeft + 1) }, (transPeriod - difference), easing, cameraeased); } else { selector.eq(slideI).show().css('z-index', '999').addClass('cameracurrent');
                            selector.eq(vis).css('z-index', '1').removeClass('cameracurrent');
                            $('.cameraContent', fakeHover).eq(slideI).addClass('cameracurrent');
                            $('.cameraContent', fakeHover).eq(vis).removeClass('cameracurrent');
                            tApp.delay((((transPeriod + difference) / blocks) * delay[index] * couples) * 0.5).css({ 'display': 'block', 'height': Math.floor((h / rows) + addTop + 1), 'margin-top': 0, 'margin-left': 0, 'opacity': 1, 'width': Math.floor((w / cols) + addLeft + 1) }).animate({ 'height': height, 'margin-left': marginLeft, 'margin-top': marginTop, 'width': width, 'opacity': opacityOnGrid }, (transPeriod - difference), easing, cameraeased); } } }); } }
        if ($(prevNav).length) { $(prevNav).click(function() { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($('.cameraSlide.cameracurrent', target).index());
                    clearInterval(u);
                    imgFake();
                    $('#' + pieID + ', .camera_canvas_wrap', wrap).animate({ opacity: 0 }, 0);
                    canvasLoader(); if (idNum != 0) { nextSlide(idNum); } else { nextSlide(amountSlide); } opts.onStartLoading.call(this); } }); }
        if ($(nextNav).length) { $(nextNav).click(function() { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($('.cameraSlide.cameracurrent', target).index());
                    clearInterval(u);
                    imgFake();
                    $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 0);
                    canvasLoader(); if (idNum == amountSlide - 1) { nextSlide(1); } else { nextSlide(idNum + 2); } opts.onStartLoading.call(this); } }); }
        if (isMobile()) { fakeHover.bind('swipeleft', function(event) { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($('.cameraSlide.cameracurrent', target).index());
                    clearInterval(u);
                    imgFake();
                    $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 0);
                    canvasLoader(); if (idNum == amountSlide - 1) { nextSlide(1); } else { nextSlide(idNum + 2); } opts.onStartLoading.call(this); } });
            fakeHover.bind('swiperight', function(event) { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($('.cameraSlide.cameracurrent', target).index());
                    clearInterval(u);
                    imgFake();
                    $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 0);
                    canvasLoader(); if (idNum != 0) { nextSlide(idNum); } else { nextSlide(amountSlide); } opts.onStartLoading.call(this); } }); }
        if ($(pagination).length) { $('.camera_pag li', wrap).click(function() { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($(this).index()); var curNum = parseFloat($('.cameraSlide.cameracurrent', target).index()); if (idNum != curNum) { clearInterval(u);
                        imgFake();
                        $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 0);
                        canvasLoader();
                        nextSlide(idNum + 1);
                        opts.onStartLoading.call(this); } } }); }
        if ($(thumbs).length) { $('.pix_thumb img', thumbs).click(function() { if (!elem.hasClass('camerasliding')) { var idNum = parseFloat($(this).parents('li').index()); var curNum = parseFloat($('.cameracurrent', target).index()); if (idNum != curNum) { clearInterval(u);
                        imgFake();
                        $('#' + pieID + ', .camera_canvas_wrap', camera_thumbs_wrap).animate({ opacity: 0 }, 0);
                        $('.pix_thumb', thumbs).removeClass('cameracurrent');
                        $(this).parents('li').addClass('cameracurrent');
                        canvasLoader();
                        nextSlide(idNum + 1);
                        thumbnailPos();
                        opts.onStartLoading.call(this); } } });
            $('.camera_thumbs_cont .camera_prevThumbs', camera_thumbs_wrap).hover(function() { $(this).stop(true, false).animate({ opacity: 0.85 }, 250); }, function() { $(this).stop(true, false).animate({ opacity: .7 }, 250); });
            $('.camera_prevThumbs', camera_thumbs_wrap).click(function() { var sum = 0,
                    wTh = $(thumbs).outerWidth(),
                    offUl = $('ul', thumbs).offset().left,
                    offDiv = $('> div', thumbs).offset().left,
                    ulLeft = offDiv - offUl;
                $('.camera_visThumb', thumbs).each(function() { var tW = $(this).outerWidth();
                    sum = sum + tW; }); if (ulLeft - sum > 0) { $('ul', thumbs).animate({ 'margin-left': '-' + (ulLeft - sum) + 'px' }, 500, thumbnailVisible); } else { $('ul', thumbs).animate({ 'margin-left': 0 }, 500, thumbnailVisible); } });
            $('.camera_thumbs_cont .camera_nextThumbs', camera_thumbs_wrap).hover(function() { $(this).stop(true, false).animate({ opacity: 0.85 }, 250); }, function() { $(this).stop(true, false).animate({ opacity: .7 }, 250); });
            $('.camera_nextThumbs', camera_thumbs_wrap).click(function() { var sum = 0,
                    wTh = $(thumbs).outerWidth(),
                    ulW = $('ul', thumbs).outerWidth(),
                    offUl = $('ul', thumbs).offset().left,
                    offDiv = $('> div', thumbs).offset().left,
                    ulLeft = offDiv - offUl;
                $('.camera_visThumb', thumbs).each(function() { var tW = $(this).outerWidth();
                    sum = sum + tW; }); if (ulLeft + sum + sum < ulW) { $('ul', thumbs).animate({ 'margin-left': '-' + (ulLeft + sum) + 'px' }, 500, thumbnailVisible); } else { $('ul', thumbs).animate({ 'margin-left': '-' + (ulW - wTh) + 'px' }, 500, thumbnailVisible); } }); }
    }
})(jQuery);;
(function($) { $.fn.cameraStop = function() { var wrap = $(this),
            elem = $('.camera_src', wrap),
            pieID = 'pie_' + wrap.index();
        elem.addClass('stopped'); if ($('.camera_showcommands').length) { var camera_thumbs_wrap = $('.camera_thumbs_wrap', wrap); } else { var camera_thumbs_wrap = wrap; } } })(jQuery);;
(function($) { $.fn.cameraPause = function() { var wrap = $(this); var elem = $('.camera_src', wrap);
        elem.addClass('paused'); } })(jQuery);;
(function($) { $.fn.cameraResume = function() { var wrap = $(this); var elem = $('.camera_src', wrap); if (typeof autoAdv === 'undefined' || autoAdv !== true) { elem.removeClass('paused'); } } })(jQuery);